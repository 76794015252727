<script>
import moment from "moment";

export default {
  name: "ItemChallengeFinished",
  setup() {
    return {
      moment: moment,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="winner-card mb-30">
    <div class="winner-card__thumb">
      <img
        :src="require(`@/assets/images/contest-${item.level}.png`)"
        alt="image"
      />
    </div>
    <div class="winner-card__content">
      <div class="winner-thumb">
        <img
          :src="getAvatar(item.winners[0].account)"
          alt="avatar"
          class="img-circle"
        />
      </div>
      <div class="content-top">
        <div class="left">
          <h5>
            {{ item.winners[0].account.ucode }}
          </h5>
        </div>
        <div class="right">
          <span>
            {{ $t("text.finalized") }}
          </span>
          <p>
            {{ moment(item.finishedAt).format("LLL") }}
          </p>
        </div>
      </div>
      <div class="content-bottom">
        <div class="number-list-wrapper">
          <p>
            {{ `${$t("text.winning-number")}:` }}
          </p>
          <ul class="number-list mt-2">
            <li>
              {{ item.winners[0].position }}
            </li>
          </ul>
        </div>
        <div class="right">
          <p>
            {{ `${$t("text.contest-number")}:` }}
          </p>
          <span class="contest-num">
            {{ item.number }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
