<script>
import lodash from "lodash";

export default {
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    filled: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    percent() {
      return lodash.floor((this.filled * 100) / this.total, 2);
    },
  },
};
</script>

<template>
  <div class="ticket-amount">
    <span class="left">
      {{ filled }}
    </span>
    <span class="right">
      {{ total }}
    </span>
    <div class="progress-bar">
      <div class="bar" :style="`width:${percent}%`"></div>
    </div>
    <p>
      {{ $t("text.only-remaining", { value: total - filled }) }}
    </p>
  </div>
</template>

<style>
.progress-bar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  background-color: #3b2bb1;
  margin-top: 5px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.progress-bar .bar {
  position: absolute;
  width: 0px;
  height: 100%;
  top: 0;
  left: 0;
  background: #ec6624;
  overflow: hidden;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}
</style>