<script>
import ItemProduct from "@/components/item_product";
import lodash from "lodash";

export default {
  name: "Products",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    summary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      items: [],
      limit: 20,
      total: 0,
      loading: false,
    };
  },
  watch: {
    page() {
      this.vchallengeShopProductSeekGet();
    },
  },
  methods: {
    refresh() {
      this.items = [];
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.vchallengeShopProductSeekGet();
      }
    },
    vchallengeShopProductSeekGet() {
      this.loading = true;
      const params = {
        skip: (this.page - 1) * this.limit,
        limit: this.summary ? 6 : this.limit,
      };

      this.$store
        .dispatch("vchallengeShopProductSeekGet", {
          params: params,
        })
        .then((response) => {
          this.items = response.data.data.items;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.total = 0;
          this.$store.dispatch("error", this.getErrorMessage(error));
        });
    },
  },
  created() {
    this.vchallengeShopProductSeekGet();
  },
  components: {
    ItemProduct,
  },
};
</script>

<template>
  <div class="wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.3s">
    <div class="row mb-none-30">
      <template v-for="item in items" :key="`product-${item._id}`">
        <div class="col-xl-4 col-md-6 mb-30">
          <ItemProduct :item="item" />
        </div>
      </template>
    </div>
  </div>

  <template v-if="!summary && !lodash.isEmpty(items)">
    <pagination
      v-model="page"
      :records="total"
      :per-page="limit"
      :options="{
        hideCount: true,
      }"
    ></pagination>
  </template>

  <template v-if="!loading">
    <template v-if="summary && total > 6">
      <div class="row mt-30">
        <div class="col-lg-12">
          <div class="btn-grp">
            <router-link :to="{ name: 'products' }" tag="a" class="btn-border">
              {{ $t("button.view-more") }}
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template v-if="lodash.isEmpty(items)">
      <div class="text-center">
        <p class="text-center">
          {{ $t("text.there-are-no-products-display") }}
        </p>
        <img src="@/assets/images/empty.png" alt="" width="350" />
      </div>
    </template>
  </template>
</template>

<style >
.pagination {
  justify-content: center !important;
}
</style>