<template>
  <!-- hero start -->
  <section class="hero">
    <div class="hero__shape">
      <img src="@/assets/images/elements/hero-shape.jpg.png" alt="image" />
    </div>
    <div class="hero__element">
      <img src="@/assets/images/elements/hero-building.png" alt="image" />
    </div>
    <div
      class="hero__car wow bounceIn"
      data-wow-duration="0.5s"
      data-wow-delay="1s"
    >
      <img
        src="@/assets/images/elements/car-shadow.png"
        alt="image"
        class="car-shadow"
      />
      <img
        src="@/assets/images/elements/car-ray.png"
        alt="image"
        class="car-ray"
      />
      <img
        src="@/assets/images/elements/car-light.png"
        alt="image"
        class="car-light"
      />
      <img
        src="@/assets/images/elements/hero-car.png"
        alt="image"
        class="hero-car"
      />
      <img
        src="@/assets/images/elements/car-star.png"
        alt="image"
        class="car-star"
      />
    </div>
    <div class="container">
      <div class="row justify-content-center justify-content-lg-start">
        <div class="col-lg-6 col-md-8">
          <div class="hero__content">
            <div
              class="hero__subtitle wow fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.3s"
            >
              {{ $t("text.a-new-opportunity") }}
            </div>
            <h2
              class="hero__title wow fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              {{ $t("text.great-prizes") }}
            </h2>
            <p
              class="wow fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.7s"
            >
              {{ $t("text.great-prizes-description") }}
            </p>
            <div
              class="hero__btn wow fadeInUp"
              data-wow-duration="0.5s"
              data-wow-delay="0.9s"
            >
              <router-link :to="{ name: 'products' }" tag="a" class="cmn-btn">
                {{ $t("button.buy-now") }}
              </router-link>
              <!-- <a
                class="video-btn"
                href="https://www.youtube.com/embed/d6xn5uflUjg"
                data-rel="lightcase:myCollection"
              >
                <i class="fas fa-play"></i>
              </a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="hero__thumb">
            <img src="@/assets/images/elements/car-main.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- hero start -->

  <!-- how to play section start -->
  <section class="position-relative pt-120 pb-120 overflow-hidden">
    <div
      class="play-elements wow bounceIn"
      data-wow-duration="0.5s"
      data-wow-delay="0.7s"
    >
      <img src="@/assets/images/elements/play-el.png" alt="image" />
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-6 text-sm-left text-center wow fadeInUp"
          data-wow-duration="0.5s"
          data-wow-delay="0.3s"
        >
          <div class="section-header">
            <span class="section-sub-title">
              {{ $t("text.need-to-know-about") }}
            </span>
            <h2 class="section-title">
              {{ $t("text.how-to-participate") }}
            </h2>
            <p>
              {{ $t("text.follow-these-easy-steps") }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="row mb-none-30 justify-content-xl-start justify-content-center"
      >
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="@/assets/images/elements/card-bg-1.jpg"
          >
            <div class="play-card__icon">
              <img src="@/assets/images/icon/play/1.png" alt="image-icon" />
              <span class="play-card__number">01</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">
                {{ $t("text.choose") }}
              </h3>
              <p>
                {{ $t("text.register-to-rrenova-choose-your-products") }}
              </p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="@/assets/images/elements/card-bg-2.jpg"
          >
            <div class="play-card__icon">
              <img src="@/assets/images/icon/play/2.png" alt="image-icon" />
              <span class="play-card__number">02</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">
                {{ $t("text.buy") }}
              </h3>
              <p>
                {{ $t("text.buy-products-receive-tickets") }}
              </p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
        <div class="col-xl-3 col-lg-4 col-sm-6 mb-30">
          <div
            class="play-card bg_img"
            data-background="@/assets/images/elements/card-bg-3.jpg"
          >
            <div class="play-card__icon">
              <img src="@/assets/images/icon/play/3.png" alt="image-icon" />
              <span class="play-card__number">03</span>
            </div>
            <div class="play-card__content">
              <h3 class="play-card__title">
                {{ $t("text.win") }}
              </h3>
              <p>
                {{ $t("text.be-winner-take-home-best-prizes") }}
              </p>
            </div>
          </div>
          <!-- play-card end -->
        </div>
      </div>
    </div>
  </section>
  <!-- how to play section end -->

  <!-- contest section start -->
  <section class="position-relative pt-120 pb-120">
    <div class="bg-el">
      <img src="@/assets/images/elements/contest-bg.png" alt="image" />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-8 wow fadeInUp"
          data-wow-duration="0.5s"
          data-wow-delay="0.3s"
        >
          <div class="section-header text-center">
            <span class="section-sub-title">
              {{ $t("text.a-more-fun-way-to-shop") }}
            </span>
            <h2 class="section-title">
              {{ $t("text.available-products") }}
            </h2>
            <p>
              {{ $t("text.product-purchase-description") }}
            </p>
          </div>
        </div>
      </div>
      <!-- row end -->

      <Products summary />
    </div>
  </section>
  <!-- contest section end -->

  <!-- latest winner section start  -->
  <section class="latest-winner-section position-relative pt-120 pb-120">
    <div class="el-1">
      <img src="@/assets/images/elements/w-el-1.png" alt="image" />
    </div>
    <div class="el-2">
      <img src="@/assets/images/elements/w-el-2.png" alt="image" />
    </div>
    <div class="el-3">
      <img src="@/assets/images/elements/w-el-3.png" alt="image" />
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div
          class="col-lg-8 wow fadeInUp"
          data-wow-duration="0.5s"
          data-wow-delay="0.3s"
        >
          <div class="section-header text-center">
            <span class="section-sub-title">
              {{ $t("text.meet-latest-winners-favorite-contest") }}
            </span>
            <h2 class="section-title">
              {{ $t("text.latest-winners") }}
            </h2>
            <p>
              {{ $t("text.check-winners-contest-past-progress-participate") }}
            </p>
          </div>
        </div>
      </div>
      <!-- row end -->

      <div
        class="row wow fadeInUp"
        data-wow-duration="0.5s"
        data-wow-delay="0.5s"
      >
        <div class="col-lg-12">
          <ul class="nav nav-tabs winner-tab-nav" id="winnerTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="products-tab"
                data-toggle="tab"
                href="#products"
                role="tab"
                aria-controls="products"
                aria-selected="false"
              >
                <div class="icon-thumb">
                  <img
                    src="@/assets/images/icon/winner-tab/1.png"
                    alt="image"
                  />
                </div>
                <span>
                  {{ $t("text.products") }}
                </span>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="tablet-tab"
                data-toggle="tab"
                href="#tablet"
                role="tab"
                aria-controls="tablet"
                aria-selected="false"
              >
                <div class="icon-thumb">
                  <img
                    src="@/assets/images/icon/winner-tab/2.png"
                    alt="image"
                  />
                </div>
                <span>
                  {{ $t("text.tablet") }}
                </span>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="watch-tab"
                data-toggle="tab"
                href="#watch"
                role="tab"
                aria-controls="watch"
                aria-selected="false"
              >
                <div class="icon-thumb">
                  <img
                    src="@/assets/images/icon/winner-tab/3.png"
                    alt="image"
                  />
                </div>
                <span>
                  {{ $t("text.watch") }}
                </span>
              </a>
            </li>

            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="dream-tab"
                data-toggle="tab"
                href="#dream"
                role="tab"
                aria-controls="dream"
                aria-selected="true"
              >
                <div class="icon-thumb">
                  <img
                    src="@/assets/images/icon/winner-tab/4.png"
                    alt="image"
                  />
                </div>
                <span>
                  {{ $t("text.dream-car") }}
                </span>
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="money-tab"
                data-toggle="tab"
                href="#money"
                role="tab"
                aria-controls="money"
                aria-selected="false"
              >
                <div class="icon-thumb">
                  <img
                    src="@/assets/images/icon/winner-tab/5.png"
                    alt="image"
                  />
                </div>
                <span>
                  {{ $t("text.money") }}
                </span>
              </a>
            </li>
          </ul>
          <div class="tab-content mt-50" id="winnerTabContent">
            <div
              class="tab-pane fade show active"
              id="products"
              role="tabpanel"
              aria-labelledby="products-tab"
            >
              <div class="row mb-none-30">
                <div class="col-lg-4 mb-30">
                  <CurrentContest :level="50" />
                </div>
                <div class="col-lg-8 mb-30">
                  <ChallengeFinished :level="50" :summary="true" />
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="tablet"
              role="tabpanel"
              aria-labelledby="tablet-tab"
            >
              <div class="row mb-none-30">
                <div class="col-lg-4 mb-30">
                  <CurrentContest :level="1000" />
                </div>
                <div class="col-lg-8 mb-30">
                  <ChallengeFinished :level="1000" :summary="true" />
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="watch"
              role="tabpanel"
              aria-labelledby="watch-tab"
            >
              <div class="row mb-none-30">
                <div class="col-lg-4 mb-30">
                  <CurrentContest :level="5000" />
                </div>
                <div class="col-lg-8 mb-30">
                  <ChallengeFinished :level="5000" :summary="true" />
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="dream"
              role="tabpanel"
              aria-labelledby="dream-tab"
            >
              <div class="row mb-none-30">
                <div class="col-lg-4 mb-30">
                  <CurrentContest :level="10000" />
                </div>
                <div class="col-lg-8 mb-30">
                  <ChallengeFinished :level="10000" :summary="true" />
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="money"
              role="tabpanel"
              aria-labelledby="money-tab"
            >
              <div class="row mb-none-30">
                <div class="col-lg-4 mb-30">
                  <CurrentContest :level="25000" />
                </div>
                <div class="col-lg-8 mb-30">
                  <ChallengeFinished :level="25000" :summary="true" />
                </div>
              </div>
            </div>
          </div>
          <!-- tab-content end -->
        </div>
      </div>
      <!-- row end -->
    </div>
  </section>
  <!-- latest winner section end  -->
</template>

<script>
import Products from "@/components/products.vue";
import CurrentContest from "@/components/current_contest";
import ChallengeFinished from "@/components/challenge_finished.vue";

export default {
  name: "Home",
  components: {
    Products,
    CurrentContest,
    ChallengeFinished,
  },
};
</script>
