<script>
import lodash from "lodash";

export default {
  name: "ItemProduct",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="contest-card">
    <router-link
      :to="{ name: 'product', params: { id: item._id } }"
      tag="a"
      class="item-link"
    ></router-link>
    <div class="contest-card__thumb">
      <img :src="getImage(item.media[0].src)" alt="image" />
      <div class="contest-num">
        <span class="mx-5">{{ $t("text.tickets") }}:</span>
        <h4 class="number">
          {{ `- ${lodash.floor(item.price.value / 25)} -` }}
        </h4>
      </div>
    </div>
    <div class="contest-card__content">
      <div class="left">
        <h5 class="contest-card__name">
          {{ item.title }}
        </h5>
      </div>
      <div class="right">
        <span class="contest-card__price">
          {{ `$${item.price.value} ${item.price.currency}` }}
        </span>
        <p>
          {{ $t("text.product-price") }}
        </p>
      </div>
    </div>
  </div>
</template>