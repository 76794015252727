<script>
import ItemChallengeFinished from "@/components/item_challenge_finished";
import lodash from "lodash";
import { ChallengeStatus } from "@/service/vchallenge";

export default {
  name: "ChallengeFinished",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    level: {
      type: Number,
    },
    summary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      items: [],
      limit: 10,
      total: 0,
      loading: false,
    };
  },
  watch: {
    page() {
      this.vchallengeGet();
    },
  },
  methods: {
    refresh() {
      this.items = [];
      this.total = 0;
      if (this.page > 1) {
        this.page = 1;
      } else {
        this.vchallengeGet();
      }
    },
    vchallengeGet() {
      this.loading = true;

      const payload = {
        params: {
          skip: (this.page - 1) * this.limit,
          limit: this.summary ? 5 : this.limit,
          status: ChallengeStatus.FINISHED,
        },
      };

      if (!lodash.isNil(this.level)) {
        lodash.assign(payload.params, {
          level: this.level,
        });
      }

      this.$store
        .dispatch("vchallengeGet", payload)
        .then((response) => {
          this.items = response.data.data.items;
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.total = 0;
          this.$store.dispatch("error", this.getErrorMessage(error));
        });
    },
  },
  mounted() {
    this.vchallengeGet();
  },
  components: {
    ItemChallengeFinished,
  },
};
</script>

<template>
  <ItemChallengeFinished
    v-for="item in items"
    :item="item"
    :key="`item-challenge-finished-${item._id}`"
  />

  <template v-if="!summary && !lodash.isEmpty(items)">
    <pagination
      v-model="page"
      :records="total"
      :per-page="limit"
      :options="{
        hideCount: true,
      }"
    ></pagination>
  </template>

  <template v-if="!loading">
    <template v-if="summary && total > 5">
      <div class="row mt-30">
        <div class="col-lg-12">
          <div class="btn-grp">
            <router-link :to="{ name: 'contests' }" tag="a" class="btn-border">
              {{ $t("button.view-more") }}
            </router-link>
          </div>
        </div>
      </div>
    </template>

    <template v-if="lodash.isEmpty(items)">
      <div class="text-center">
        <p class="text-center">
          {{ $t("text.there-are-no-completed-contests-display") }}
        </p>
        <img src="@/assets/images/empty.png" alt="" width="350" />
      </div>
    </template>
  </template>
</template>

<style >
.pagination {
  justify-content: center !important;
}
</style>