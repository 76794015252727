<script>
import { ChallengeStatus } from "@/service/vchallenge";
import lodash from "lodash";
import ProgressBarContest from "./progress_bar_contest.vue";

export default {
  name: "CurrentContest",
  setup() {
    return {
      lodash: lodash,
    };
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      item: null,
      loading: false,
    };
  },
  computed: {
    positionsFilled() {
      if (!lodash.isNil(this.item)) {
        if (this.level > 50) {
          return lodash.floor(this.item.participants.length / 50);
        }
        return this.item.participants.length;
      }
      return 0;
    },
    total() {
      if (this.level > 50) {
        return lodash.floor(this.level / 50);
      }
      return this.level;
    },
    numbers() {
      if (
        !lodash.isNil(this.item) &&
        !lodash.isEmpty(this.item.participants) &&
        this.$store.getters.isAuthenticated &&
        !lodash.isNil(this.$store.getters.account)
      ) {
        const positions = [];
        const id = this.$store.getters.account._id;

        for (let index = 0; index < this.item.participants.length; index++) {
          if (this.item.participants[index] == id) {
            positions.push(index + 1);
          }
        }

        return positions;
      }
      return [];
    },
  },
  methods: {
    vchallengeGet() {
      this.loading = true;
      this.$store
        .dispatch("vchallengeGet", {
          params: {
            limit: 1,
            status: ChallengeStatus.INPROGRESS,
            level: this.level,
          },
        })
        .then((response) => {
          this.item = lodash.head(response.data.data.items);
          this.loading = false;
        })
        .catch((error) => {
          this.$store.dispatch("error", this.getErrorMessage(error));
          this.loading = false;
        });
    },
  },
  mounted() {
    this.vchallengeGet();
  },
  components: {
    ProgressBarContest,
  },
};
</script>

<template>
  <div class="contest-sidebar">
    <div class="contest-sidebar__cart">
      <h3 class="title">
        {{ $t("text.current-contest") }}
      </h3>
      <p>
        <template v-if="level == 50">
          {{
            $t("text.positions-filled", {
              value: positionsFilled,
              total: total,
            })
          }}
        </template>
        <template v-else>
          {{
            $t("text.cycles-completed", {
              value: positionsFilled,
              total: total,
            })
          }}
        </template>
      </p>

      <ProgressBarContest :filled="positionsFilled" :total="total" />

      <template
        v-if="
          $store.getters.isAuthenticated &&
          !lodash.isNil($store.getters.account)
        "
      >
        <form class="check-number-form mt-30" v-if="!lodash.isEmpty(numbers)">
          <div class="number-list-wrapper">
            <p>
              {{ $t("text.your-numbers-in-this-contest") }}
            </p>
            <div class="number-list mt-3 mb-3">
              <template v-for="number in numbers" :key="`my-number-${number}`">
                <span class="mr-2 number-item">
                  {{ number }}
                </span>
              </template>
            </div>
          </div>
        </form>
      </template>
    </div>
  </div>
</template>

<style>
.number-item {
  min-width: 36px;
  height: 36px;
  background-image: -moz-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: -webkit-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: -ms-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  box-shadow: 0px 19px 56px 0px rgb(0 0 0 / 20%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 18px;
  color: #c4cbf9;
  border: none;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
}
</style>